import React from 'react';

import SubLayout from '../components/SubLayout';

const NotFoundPage = () => (
  <SubLayout topTitle="お探しのページが見つかりません">
    <p>申し訳ございません、お探しのページが見つかりませんでした。</p>
  </SubLayout>
);

export default NotFoundPage;
